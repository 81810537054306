import styled from "@emotion/styled"
import React from "react"
import { H2, P, TextCTA } from "../Partials/Typography"
import ReactHtmlParser from "react-html-parser"
import VerticalSpacer from "../Partials/VerticalSpacer"

const Client = ({ client, description, slug }) => {
    return (
        <>
            <SelectClient>
                <H2 isTan>{ReactHtmlParser(client)}</H2>
                <VerticalSpacer size="s" />
                <P isTan>{ReactHtmlParser(description)}</P>
                {slug ? (
                    <>
                        <LinkWrapper>
                            <TextCTA hasLine to={slug} isTan>
                                Read Case Study &nbsp; &rarr;
                            </TextCTA>
                        </LinkWrapper>
                    </>
                ) : null}
            </SelectClient>
        </>
    )
}

export default Client

const SelectClient = styled.div`
    display: flex;
    flex-direction: column;
`

const LinkWrapper = styled.div`
    margin-top: 4rem;
`
