import React from "react"
import styled from "@emotion/styled"
import Client from "./Client"
import RightContainer from "../Partials/RightContainer"
import { deviceMinW } from "../../styles/mediaQueries"
import { spacer } from "../../styles/sizes"

const ClientList = ({ clients }) => {
    return (
        <>
            <ListContainer>
                <RightContainer>
                    <List>
                        {clients.map(({ id, client, description, slug }) => (
                            <Client
                                key={id}
                                client={client}
                                description={description}
                                slug={slug}
                            />
                        ))}
                    </List>
                </RightContainer>
            </ListContainer>
        </>
    )
}
export default ClientList

const List = styled.div`
    position: relative;
    display: grid;
    grid-gap: 6rem;

    @media ${deviceMinW.tablet} {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: ${spacer.xl};
    }
`

const ListContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`
