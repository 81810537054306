import React from "react"
import { get } from "lodash"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { Hero } from "../components/Partials/Hero"
import ClientList from "../components/Clients/ClientList"
import useToggleFooterColor from "../hooks/useToggleFooterColor"
import Sidebar from "../components/Sidebar/Sidebar"
import AppContainer from "../styles/AppContainer"
import VerticalSpacer from "../components/Partials/VerticalSpacer"
import { colors } from "../styles/colors"
import { Helmet } from "react-helmet"

const Clients = ({ data }) => {
    const { heading, meta } = get(data, "allClientsJson.edges[0].node", {})
    const { copy: headingCopy } = heading
    const { image: metaImage } = meta

    const { clients: clientName = [] } = get(
        data,
        "allClientListJson.edges[0].node",
        {}
    )

    const doesCTAExist = !!headingCopy.slug

    useToggleFooterColor({ useTan: true })

    return (
        <>
            <SEO
                title="Clients | Descriptive"
                description="A select list of clients we've been fortunate to work with over the years."
                image={metaImage}
            />
            <Helmet bodyAttributes={{ class: "clients-page" }} />
            <AppContainer>
                <Sidebar copy="Clients" fontColor={colors.electricViolet} />
                <Hero heading={headingCopy} ctaExists={doesCTAExist} isTan />
                <VerticalSpacer size="xxl" />
                <ClientList clients={clientName} />
            </AppContainer>
        </>
    )
}
export default Clients

export const query = graphql`
    query {
        allClientsJson {
            edges {
                node {
                    meta {
                        image {
                            src
                            alt
                        }
                    }
                    heading {
                        copy {
                            header
                            cta
                            slug
                        }
                    }
                }
            }
        }
        allClientListJson {
            edges {
                node {
                    clients {
                        id
                        client
                        description
                        slug
                    }
                }
            }
        }
    }
`
